<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12">
            <v-btn
              class="float-right"
              outlined
              color="black"
              :to="
                currentRoles.includes('SCO$ADMINISTRADOR')
                  ? `/proyectos/cns/administracion`
                  : `/proyectos/cns/externo/registro-informacion`
              "
            >
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <span class="card-label font-weight-bolder text-dark">
              Planificación Anual del Proyecto </span
            ><br />
            <v-skeleton-loader
              v-if="skeletonLoading"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <div v-if="!skeletonLoading">
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                {{ codigoProyecto }}
              </span>
              <br />
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                {{ datosProyecto.nombreProyecto }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-btn
              color="light-blue-502"
              class="white--text mb-2 float-right  darken-2"
              dark
              @click="modalNuevo"
            >
              <v-icon slot="append" left>mdi-plus</v-icon>
              Registrar Programación Anual
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="planificaciones"
          :search="filtro"
          :loading="tablePlanificacionesLoading"
          no-data-text="No se encontraron registros"
          no-results-text="No se encontraron registros con el filtro especificado"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Año, descripción"
                    maxlength="200"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <!--<td>{{ item.id }}</td>-->
              <td>{{ item.periodo }}</td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-4'
                      : item.estadosId === 4
                      ? 'cyan lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-1'
                      : item.estadosId === 4
                      ? 'cyan darken-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                {{ item.estadosId != 1 ? item.estado : `En registro` }}
                </v-chip>

                {{
                  item.estadosId === 1
                    ? `Debe confirmar el POA para poder registrar IAFF.`
                    : ``
                }}
              </td>
              <td>
                <!--<v-btn
                  v-if="item.estadosId === 1"
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>

                <v-btn
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-eye</v-icon> Visualizar
                </v-btn>-->

                <v-btn
                  v-if="item.estadosId === 1"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    modalConfigurarProgramacion(item.id, item.periodo, true)
                  "
                >
                  <v-icon left>mdi-file-document-outline</v-icon> Registrar
                  información
                </v-btn>

                <!-- <v-btn
                  v-if="item.estadosId === 1"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialogConfirmacion(item.id, item.periodo)"
                >
                  <v-icon left>mdi-check-all</v-icon> Confirmar información
                </v-btn> -->

               <!--  <v-btn
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    imprimirPoa(item.id, item.periodo, item.nombreProyecto)
                  "
                >
                  <v-icon left>mdi-cloud-download-outline</v-icon> Descargar Poa
                </v-btn> -->

                <v-btn
                  v-if="item.estadosId === 4"
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="descargarArchivo(item.pathProgramacion, 'sicoopera')"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon> Visualizar POA
                </v-btn>


                <v-btn
                  v-if="
                    item.estadosId === 4 &&
                      (currentRoles.includes('SCO$ADMINISTRADOR') ||
                        currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="mostrarModalActualizarEstado(item.id, item.periodo)"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-document-edit-outline</v-icon>
                  Activar POA
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="900"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Registro de programación anual de proyecto

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-form
            ref="form"
            v-on:submit.prevent="registrarItem"
            v-model="validForm"
          >
            <v-row>
              <v-col cols="12" md="6" class="pt-4 pb-0">
                <v-select
                  v-model="datosItem.periodosId"
                  :items="periodos"
                  :loading="ddPeriodosLoading"
                  dense
                  filled
                  label="Año"
                  item-text="periodo"
                  item-value="id"
                  :rules="[selectRequired('periodo / año')]"
                  :no-data-text="
                    periodos != null
                      ? 'Selecciona un periodo'
                      : 'No se han encontrado periodos'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validForm"
                  :loading="btnRegistroLoading"
                >
                  Registrar Planificación
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistroInformacion"
      max-width="1280px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Registro de programación anual de proyecto año {{ periodoSeleccionado
          }}<br />
          {{ codigoProyecto }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistroInformacion = false;
              resetItemsProgAnual();
              resetItemsProgCuatrimestre();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card elevation="0">
          <v-card-text class="pl-8 pr-8 pt-5" style="background: #f4f5f7;">
            <v-row>
              <v-col cols="12" md="6" sm="12" xs="12">
                <v-row>
                  <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
                    <v-list-item two-line>
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 font-weight-bold"
                          >Monto Suscrito Fuente Cooperante</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          parseFloat(totalSubDivisionCooperante).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ"
                            }
                          )
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                    <v-list-item two-line style="min-height: 50px;">
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 font-weight-bold"
                          >Monto Acumulado por enmiendas</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          parseFloat(datosProyecto.totalEnmiendasFuenteCoo).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ"
                            }
                          )
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                    <v-list-item two-line style="min-height: 50px;">
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 font-weight-bold"
                          >Monto Total Fuente Cooperante</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          parseFloat(datosProyecto.totalSuscritoFuenteCoo).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ"
                            }
                          )
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="12" class="pt-0 pb-0 pt-0">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                    <v-list-item two-line style="min-height: 50px;">
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 text-h6 font-weight-bold"
                          >Disponible Fuente Cooperante</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          parseFloat(totalSubDivisionCooperante).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ"
                            }
                          )
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-col>
              
              <v-col cols="12" md="6" sm="12" xs="12">
                <v-row>
                  <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
                    <v-list-item two-line style="min-height: 50px;">
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 text-h6 font-weight-bold"
                          >Monto Suscrito Contrapartida / Otros
                          Aportes</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          parseFloat(totalSubDivisionContraPartida).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ"
                            }
                          )
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                    <v-list-item two-line style="min-height: 50px;">
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 text-h6 font-weight-bold"
                          >Monto Acumulado por Enmiendas</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          parseFloat(datosProyecto.totalEnmiendasContrapartida).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ"
                            }
                          )
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
                    <v-list-item two-line style="min-height: 50px;">
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 font-weight-bold"
                          >Monto Total Contrapartida/Otros</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          parseFloat(totalSubDivisionContraPartida).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ"
                            }
                          )
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="12" class="pt-0 pb-0 pt-0">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0 pt-0">
                    <v-list-item two-line style="min-height: 50px;">
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 font-weight-bold"
                          >Disponible Contrapartida</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6"
                          >{{
                            parseFloat(
                              totalSubDivisionContraPartida
                            ).toLocaleString("es-GT", {
                              style: "currency",
                              currency: "GTQ"
                            })
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- inicio::  Programación  anual y multianual del proyecto -->
            <v-card outlined class="mt-6">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <span
                      class="card-label text-h6 font-weight-bolder text-dark"
                    >
                      Programación anual y multianual del proyecto</span
                    >
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                    <v-subheader class="text-h6 font-weight-bolder text-dark">
                      Subdivisión del monto suscrito de la Fuente Cooperante
                    </v-subheader>
                  </v-col>
                </v-row>

                <v-form
                  v-if="!seccionesBloqueadas"
                  ref="form2"
                  v-on:submit.prevent="registrarProgramacionAnual"
                  v-model="validForm2"
                >
                  <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                      <v-select
                        v-model="programacionAnualMultiFuente"
                        :items="subDivisiones"
                        :loading="false"
                        dense
                        filled
                        label="Subdivisión"
                        item-text="nombreActividad"
                        item-value="id"
                        return-object
                        :no-data-text="
                          subDivisiones != null
                            ? 'Selecciona una subdivisión'
                            : 'No se han encontrado subdivisiones'
                        "
                        menu-props="auto"
                      >
                        <template v-slot:selection="{ item }" class="pt-2">
                          {{
                            item.nombreActividad +
                              " | " +
                              item.descripcion +
                              " | " +
                              parseFloat(
                                item.aporteFuenteCooperante
                              ).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                          }}
                        </template>

                        <template v-slot:item="{ item, attrs, on }">
                          <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-row no-gutters align="center">
                                  <v-col cols="8">
                                    <v-col cols="12" class="pt-1 pb-1">
                                      <span>{{ item.nombreActividad }}</span>
                                    </v-col>
                                    <v-col cols="12" class="pt-1 pb-1">
                                      <span>{{ item.descripcion }}</span>
                                    </v-col>
                                  </v-col>
                                  <v-spacer></v-spacer>
                                  <v-chip
                                    text-color="white"
                                    color="blue-grey darken-1"
                                    small
                                    >{{
                                      parseFloat(
                                        item.aporteFuenteCooperante
                                      ).toLocaleString("es-GT", {
                                        style: "currency",
                                        currency: "GTQ"
                                      })
                                    }}</v-chip
                                  >
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        prefix="Q"
                        v-model="datosProyeccionAnual.aporteFuenteCooperante"
                        label="Aporte Fuente Cooperante"
                        :rules="[
                          required('aporte de la fuente cooperante'),
                          decimals('aporte de la fuente cooperante'),
                          maxLength('aporte de la contrapartida', 30)
                        ]"
                        maxlength="30"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12">
                      <v-btn
                        color="light-blue-502"
                        class="mb-2 float-right white--text"
                        type="submit"
                        :elevation="0"
                        :disabled="!validForm2"
                        :loading="btnRegistroProgramacionAnualLoading"
                      >
                        Registrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <v-row>
                  <!--inicio:: tabla planificacion general-->
                  <v-col cols="12" md="12" sm="12" class="pt-4">
                    <v-data-table
                      class="elevation-1"
                      :headers="headersProgramacion"
                      :items="programacionesGenerales"
                      :loading="tableProgramacionesGeneralesLoading"
                      no-data-text="No se encontraron registros"
                      no-results-text="No se encontraron registros con el filtro especificado"
                      loading-text="Cargando..."
                      :header-props="{
                        sortByText: 'Ordenar por'
                      }"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        'items-per-page-text': 'Registros por página',
                        pageText: '{0}-{1} de {2}'
                      }"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <!--<td>{{ item.id }}</td>-->
                          <td>{{ item.descripcion }}</td>
                          <td class="text-right">
                            {{
                              parseFloat(
                                item.aporteFuenteCooperante
                              ).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                            }}
                          </td>
                          <!--<td> {{ parseFloat(item.aporteContraPartida).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}} </td>-->
                          <td>
                            <v-btn
                              v-if="!seccionesBloqueadas"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="
                                eliminarSubdivisionFC(
                                  item.id,
                                  item.nombreActividad,
                                  item.descripcion
                                )
                              "
                              :disabled="btnEliminarSubdivisionFCLoading"
                            >
                              <v-icon left>mdi-delete</v-icon> Eliminar
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                  <!--fin:: tabla planificacion cuatrimestre -->
                </v-row>

                <v-row>
                  <v-col cols="12" class="pt-4">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                    <v-subheader class="text-h6 font-weight-bolder text-dark">
                      {{
                        contraPartidaRegistrada && otrosRegistrado
                          ? ` Subdivisión del monto suscrito de la Contrapartida / Otros Aportes`
                          : contraPartidaRegistrada && !otrosRegistrado
                          ? `Subdivisión del monto suscrito de la Contrapartida`
                          : !contraPartidaRegistrada && otrosRegistrado
                          ? `Otros Aportes`
                          : ``
                      }}
                    </v-subheader>
                  </v-col>
                </v-row>

                <!--inicio:: datos de contrapartida -->
                <v-form
                  v-if="contraPartidaRegistrada && !seccionesBloqueadas"
                  ref="form3"
                  v-on:submit.prevent="registrarProgramacionAnualContrapartida"
                  v-model="validFormContrapartida"
                >
                  <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                      <v-select
                        v-model="programacionAnualMultiContra"
                        :items="subDivisionesCP"
                        :loading="false"
                        dense
                        filled
                        label="Subdivisión"
                        item-text="nombreActividad"
                        item-value="id"
                        return-object
                        :no-data-text="
                          subDivisionesCP != null
                            ? 'Selecciona una subdivisión'
                            : 'No se han encontrado subdivisiones'
                        "
                        menu-props="auto"
                      >
                        <template v-slot:selection="{ item }" class="pt-2">
                          {{
                            item.nombreActividad +
                              " | " +
                              item.descripcion +
                              " | " +
                              parseFloat(item.monto).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                          }}
                        </template>

                        <template v-slot:item="{ item, attrs, on }">
                          <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-row no-gutters align="center">
                                  <v-col cols="8">
                                    <v-col cols="12" class="pt-1 pb-1">
                                      <span>{{ item.nombreActividad }}</span>
                                    </v-col>
                                    <v-col cols="12" class="pt-1 pb-1">
                                      <span>{{ item.descripcion }}</span>
                                    </v-col>
                                  </v-col>
                                  <v-spacer></v-spacer>
                                  <v-chip
                                    text-color="white"
                                    color="blue-grey darken-1"
                                    small
                                    >{{
                                      parseFloat(item.monto).toLocaleString(
                                        "es-GT",
                                        {
                                          style: "currency",
                                          currency: "GTQ"
                                        }
                                      )
                                    }}</v-chip
                                  >
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        prefix="Q"
                        v-model="datosProyeccionAnualContrapartida.monto"
                        label="Aporte Contrapartida"
                        prepend="GTQ"
                        :rules="[
                          required('aporte de la contrapartida'),
                          decimals('aporte de la contrapartida'),
                          maxLength('aporte de contrapartida', 30)
                        ]"
                        maxlength="30"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12">
                      <v-btn
                        color="light-blue-502"
                        class="mb-2 float-right white--text"
                        type="submit"
                        :elevation="0"
                        :disabled="!validFormContrapartida"
                        :loading="btnRegistroProgramacionContraPartidaLoading"
                      >
                        Registrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <v-row v-if="contraPartidaRegistrada && !seccionesBloqueadas">
                  <!--inicio:: tabla planificacion general-->
                  <v-col cols="12" md="12" sm="12" class="pt-4">
                    <v-data-table
                      class="elevation-1"
                      :headers="headersProgramacion"
                      :items="programacionesGeneralesContraPartida"
                      :loading="
                        tableProgramacionesGeneralesContraPartidaLoading
                      "
                      no-data-text="No se encontraron registros"
                      no-results-text="No se encontraron registros con el filtro especificado"
                      loading-text="Cargando..."
                      :header-props="{
                        sortByText: 'Ordenar por'
                      }"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        'items-per-page-text': 'Registros por página',
                        pageText: '{0}-{1} de {2}'
                      }"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <!--<td>{{ item.id }}</td>-->
                          <td>
                            {{
                              item.nombreActividad + " | " + item.descripcion
                            }}
                          </td>
                          <td class="text-right">
                            {{
                              parseFloat(item.monto).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                            }}
                          </td>
                          <!--<td> {{ parseFloat(item.aporteContraPartida).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}} </td>-->
                          <td>
                            <v-btn
                              v-if="!seccionesBloqueadas"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="
                                eliminarSubdivisionCP(
                                  item.id,
                                  item.nombreActividad,
                                  item.descripcion
                                )
                              "
                              :disabled="btnEliminarSubdivisionCPLoading"
                            >
                              <v-icon left>mdi-delete</v-icon> Eliminar
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                  <!--fin:: tabla planificacion cuatrimestre -->
                </v-row>
                <!--fin:: datos de contrapartida -->
              </v-card-text>
            </v-card>
            <!-- fin::  Programación  anual y multianual del proyecto -->

            <!-- inicio::  Proyección Anual del proyecto por cuatrimestre -->
            <v-card outlined class="mt-4">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <span
                      class="card-label text-h6 font-weight-bolder text-dark"
                    >
                      Proyección Anual del proyecto por cuatrimestre</span
                    >
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                  </v-col>
                </v-row>

                <v-form
                  v-if="!seccionesBloqueadas"
                  ref="form2"
                  v-on:submit.prevent="registrarProgramacionCuatrimestre"
                  v-model="validForm3"
                >
                  <v-row>
                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                      <v-select
                        v-model="datosProyeccionCuatrimestre.variablesCnsId"
                        :items="variables"
                        :loading="false"
                        dense
                        filled
                        label="Variable"
                        item-text="variable"
                        item-value="id"
                        :no-data-text="
                          variables != null
                            ? 'Selecciona una variable'
                            : 'No se han encontrado variables'
                        "
                        menu-props="auto"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        filled
                        :disabled="!datosProyeccionCuatrimestre.variablesCnsId"
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        :prefix="
                          datosProyeccionCuatrimestre.variablesCnsId === 1 ||
                          datosProyeccionCuatrimestre.variablesCnsId === 2
                            ? `Q`
                            : datosProyeccionCuatrimestre.variablesCnsId === 3
                            ? `%`
                            : `N/A`
                        "
                        v-model="
                          datosProyeccionCuatrimestre.montoPrimerCuatrimestre
                        "
                        label="Primer cuatrimestre"
                        :rules="[
                          required('primer cuatrimestre'),
                          decimals('primer cuatrimestre'),
                          maxLength('primer cuatrimestre', 30)
                        ]"
                        maxlength="30"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        filled
                        :disabled="!datosProyeccionCuatrimestre.variablesCnsId"
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        :prefix="
                          datosProyeccionCuatrimestre.variablesCnsId === 1 ||
                          datosProyeccionCuatrimestre.variablesCnsId === 2
                            ? `Q`
                            : datosProyeccionCuatrimestre.variablesCnsId === 3
                            ? `%`
                            : `N/A`
                        "
                        v-model="
                          datosProyeccionCuatrimestre.montoSegundoCuatrimestre
                        "
                        label="Segundo cuatrimestre"
                        :rules="[
                          required('segundo cuatrimestre'),
                          decimals('segundo cuatrimestre'),
                          maxLength('segundo cuatrimestre', 30)
                        ]"
                        maxlength="30"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        filled
                        :disabled="!datosProyeccionCuatrimestre.variablesCnsId"
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        :prefix="
                          datosProyeccionCuatrimestre.variablesCnsId === 1 ||
                          datosProyeccionCuatrimestre.variablesCnsId === 2
                            ? `Q`
                            : datosProyeccionCuatrimestre.variablesCnsId === 3
                            ? `%`
                            : `N/A`
                        "
                        v-model="
                          datosProyeccionCuatrimestre.montoTercerCuatrimestre
                        "
                        label="Tercer cuatrimestre"
                        :rules="[
                          required('tercer cuatrimestre'),
                          decimals('tercer cuatrimestre'),
                          maxLength('tercer cuatrimestre', 30)
                        ]"
                        maxlength="30"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-btn
                        color="light-blue-502"
                        class="mb-2 float-right white--text"
                        type="submit"
                        :elevation="0"
                        :disabled="!validForm3"
                        :loading="btnRegistroProgramacionCuatrimestralLoading"
                      >
                        Registrar programación cuatrimestral
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <v-row>
                  <!--inicio:: tablaplanificacion cuatrimestre-->
                  <v-col cols="12" md="12" sm="12" class="pt-4">
                    <v-data-table
                      class="elevation-1"
                      :headers="headersCuatrimestre"
                      :items="programacionesCuatrimestre"
                      :loading="tableProgramacionesCuatrimestreLoading"
                      no-data-text="No se encontraron registros"
                      no-results-text="No se encontraron registros con el filtro especificado"
                      loading-text="Cargando..."
                      :header-props="{
                        sortByText: 'Ordenar por'
                      }"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        'items-per-page-text': 'Registros por página',
                        pageText: '{0}-{1} de {2}'
                      }"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="text-right">{{ item.variable }}</td>
                          <td class="text-right">
                            {{
                              item.variablesCNSId === 1 ||
                              item.variablesCNSId === 2
                                ? parseFloat(
                                    item.montoPrimerCuatrimestre
                                  ).toLocaleString("es-GT", {
                                    style: "currency",
                                    currency: "GTQ"
                                  })
                                : item.montoPrimerCuatrimestre + `%`
                            }}
                          </td>
                          <td class="text-right">
                            {{
                              item.variablesCNSId === 1 ||
                              item.variablesCNSId === 2
                                ? parseFloat(
                                    item.montoSegundoCuatrimestre
                                  ).toLocaleString("es-GT", {
                                    style: "currency",
                                    currency: "GTQ"
                                  })
                                : item.montoSegundoCuatrimestre + `%`
                            }}
                          </td>
                          <td class="text-right">
                            {{
                              item.variablesCNSId === 1 ||
                              item.variablesCNSId === 2
                                ? parseFloat(
                                    item.montoTercerCuatrimestre
                                  ).toLocaleString("es-GT", {
                                    style: "currency",
                                    currency: "GTQ"
                                  })
                                : item.montoTercerCuatrimestre + `%`
                            }}
                          </td>
                          <td>
                            <v-btn
                              v-if="!seccionesBloqueadas"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="
                                eliminarProyeccionCuatrimestre(
                                  item.id,
                                  variable
                                )
                              "
                              :disabled="btnEliminarProyeccionCuatrimestreLoading"
                            >
                              <v-icon left>mdi-delete</v-icon> Eliminar
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <!--fin:: tabla planificacion cuatrimestre -->
              </v-card-text>
            </v-card>
            <!-- fin::  Proyección Anual del proyecto por cuatrimestre -->

            <!-- inicio:: monto asignado inicio ciclo fiscal -->
            <v-card
              outlined
              class="mt-4"
              v-if="datosProyecto.tiposCooperacionId == 1"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <span
                      class="card-label text-h6 font-weight-bolder text-dark"
                    >
                      Monto Asignado al Inicio del Ciclo Fiscal
                    </span>
                    <br />
                    <span
                      class="red--text mt-3 font-weight-bold text-subtitle-1"
                    >
                      *Ingresar el monto asignado para el año en curso (Según
                      SICOIN) - Cantidad en Quetzales
                    </span>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                  </v-col>
                </v-row>

                <v-form
                  ref="form2"
                  v-on:submit.prevent="registrarMontoAsignado"
                  v-model="validFormMontoAsignado"
                >
                  <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        prefix="Q"
                        v-model="datosMontoAsignado.montoAsignado"
                        label="Monto asignado para el año en curso"
                        :rules="[
                          required('monto asignado'),
                          decimals('monto asignado'),
                          maxLength('monto asignado', 20)
                        ]"
                        maxlength="20"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12">
                      <v-btn
                        v-if="!seccionesBloqueadas"
                        color="light-blue-502"
                        class="mb-2 float-right white--text"
                        type="submit"
                        :elevation="0"
                        :disabled="!validFormMontoAsignado"
                        :loading="btnRegistroMontoAsignadoLoading"
                      >
                        Actualizar el monto asignado
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

            <!-- inicio:: monto asignado inicio ciclo fiscal -->
            <v-card outlined class="mt-4">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <span
                      class="card-label text-h6 font-weight-bolder text-dark"
                    >
                      Confirmación de la Programación Operativa Anual
                    </span>
                    <br />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" sm="6" xs="12">
                    <v-card flat outlined>
                      <v-card-title
                        class="text-subtitle-1"
                        :color="
                          programacionesGenerales.length > 0
                            ? `#26c6da`
                            : `orange darken-2`
                        "
                      >
                      Subdivisión del monto suscrito de la Fuente Cooperante
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12" class="text-center">
                            <v-icon
                              x-large
                              :color="
                                programacionesGenerales.length > 0
                                  ? `#26c6da`
                                  : `orange darken-2`
                              "
                              >{{
                                programacionesGenerales.length > 0
                                  ? "mdi-check-all"
                                  : "mdi-alert-circle-outline"
                              }}
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    v-if="contraPartidaRegistrada || otrosRegistrado"
                    cols="12"
                    md="4"
                    sm="6"
                    xs="12"
                  >
                    <v-card flat outlined>
                      <v-card-title
                        class="text-subtitle-1"
                        :color="
                          programacionesGeneralesContraPartida.length > 0
                            ? `#26c6da`
                            : `orange darken-2`
                        "
                      >
                        Subdivisión del monto suscrito de la Contrapartida /
                        Otros Aportes
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12" class="text-center">
                            <v-icon
                              x-large
                              :color="
                                programacionesGeneralesContraPartida.length > 0
                                  ? `#26c6da`
                                  : `orange darken-2`
                              "
                              >{{
                                programacionesGeneralesContraPartida.length > 0
                                  ? "mdi-check-all"
                                  : "mdi-alert-circle-outline"
                              }}
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="4" sm="6" xs="12">
                    <v-card flat outlined>
                      <v-card-title
                        class="text-subtitle-1"
                        :color="
                          programacionesCuatrimestre.length > 0
                            ? `#26c6da`
                            : `orange darken-2`
                        "
                      >
                        Proyección Anual del proyecto por cuatrimestre
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12" class="text-center">
                            <v-icon
                              x-large
                              :color="
                                programacionesCuatrimestre.length > 0
                                  ? `#26c6da`
                                  : `orange darken-2`
                              "
                              >{{
                                programacionesCuatrimestre.length > 0
                                  ? "mdi-check-all"
                                  : "mdi-alert-circle-outline"
                              }}
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    sm="6"
                    xs="12"
                    v-if="datosProyecto.tiposCooperacionId == 1"
                  >
                    <v-card flat outlined>
                      <v-card-title
                        class="text-subtitle-1"
                        :color="
                          datosMontoAsignado.montoAsignado != null
                            ? `#26c6da`
                            : `orange darken-2`
                        "
                      >
                        Monto Asignado al Inicio del Ciclo Fiscal
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12" class="text-center">
                            <v-icon
                              x-large
                              :color="
                                datosMontoAsignado.montoAsignado != null
                                  ? `#26c6da`
                                  : `orange darken-2`
                              "
                              >{{
                                datosMontoAsignado.montoAsignado != null
                                  ? "mdi-check-all"
                                  : "mdi-alert-circle-outline"
                              }}
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>


                </v-row>

                <v-row v-if="datosProgramacion.estadosId === 1">
                  <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                    <v-row>
                      <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                        <v-subheader
                          class="text-h6 font-weight-bolder text-dark"
                        >
                          Confirme la información registrada en la presente
                          Programación Operativa Anual registrada.
                        </v-subheader>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" xs="12" class="text-center">
                    <v-btn
                      v-if="
                        programacionesGenerales.length > 0 &&
                          ((subDivisionesCP.length > 0 &&
                            programacionesGeneralesContraPartida.length > 0) ||
                            (subDivisionesCP.length <= 0 &&
                              programacionesGeneralesContraPartida.length <= 0
                              )) &&
                          programacionesCuatrimestre.length > 0 &&
                          ((datosProyecto.tiposCooperacionId == 1 &&
                            datosMontoAsignado.montoAsignado != null) || datosProyecto.tiposCooperacionId != 1)
                      "
                      :disabled="datosProgramacion.estadosId != 1"
                      color="light-blue-502"
                      class="mb-2 white--text"
                      x-large
                      depressed
                      @click="
                        mostrarDialogConfirmacion(
                          datosProgramacion.id,
                          datosProgramacion.periodo
                        )
                      "
                    >
                      <v-icon left>mdi-check-all</v-icon> Confirmar Información
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="datosProgramacion.estadosId != 1">
                  <v-col cols="12" md="12" sm="12" class="text-center">
                    <v-icon large color="green darken-2">
                      mdi-check-all
                    </v-icon>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="text-center">
                    <p class="text-h6">
                      La programación operativa anual
                      {{ periodoSeleccionado }} ya se encuentra confirmada
                    </p>
                  </v-col>
                </v-row>

                <v-row v-if="datosProgramacion.estadosId == 1">
                  <v-col cols="12" md="12" sm="12" class="text-center">
                    <v-icon large color="orange darken-2">
                      mdi-alert-outline
                    </v-icon>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="text-center">
                    <p class="text-h6">
                      Es necesario confirmar la programación operativa anual
                      para poder registrar el Informe de Avance Físico y
                      Financiero correspondiente al año
                      {{ periodoSeleccionado }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <!-- fin:: monto asignado inicio ciclo fiscal -->
          </v-card-text>
        </v-card>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>

    <!--end:: modal registro-->

    <!--begin:: dialog confirmar información -->
    <v-dialog
      v-model="dialogConfirmacion"
      width="500"
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirmación de la información

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogConfirmacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                ¿Está seguro de confirmar la información de la Planificación
                Anual del Proyecto {{ periodoSeleccionado }}?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogConfirmacion = false"
            :disabled="btnEnvioLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="confirmarPlanificacion"
            :loading="btnEnvioLoading"
          >
            Sí, confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: dialog confirmar información -->
    <v-dialog
      v-model="dialogActualizarEstado"
      width="500"
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          Activar programación anual
          {{ periodoSeleccionado }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogActualizarEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                ¿Está seguro de activar nuevamente la Planificación Anual
                del año {{ periodoSeleccionado }}?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogActualizarEstado = false"
            :disabled="btnEnvioLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="actualizarEstadoPlanificacion()"
            :loading="btnEnvioLoading"
          >
            Sí, activar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--inicio:: impresion poa -->
<!--     <template>
      <div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          :filename="`poa-${codigoProyecto}-${periodoSeleccionado}`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="letter"
          pdf-orientation="portrait"
          pdf-content-width="780px"
          :margin="[10, 100]"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content" class="pdf-content pl-6">
            <div class="row">
              <div class="col col-md-12 col-sm-12">
                <img width="150" src="media/logos/logo-light.png" />
              </div>
            </div>
            <div class="row mt-6">
              <div class="col col-sm-12 col-md-12">
                <p
                  class="text-h6 text-uppercase text-center font-weight-medium black--text"
                  style="font-weight: 600; padding-bottom: 0px; margin-bottom: 1px;"
                >
                  Plan operativo Anual {{ periodoSeleccionado }}
                </p>
                <p
                  class="text-h6 text-uppercase text-center font-weight-medium black--text"
                  style="font-weight: 600; padding-top:5px; margin-bottom: 5px; margin-top: 5px"
                >
                  {{ codigoProyecto }}
                </p>
                <p
                  class="text-h6 text-center"
                  style="font-weight: 600; padding-top:5px; margin-bottom: 5px; margin-top: 5px;"
                >
                  {{ proyectoSeleccionado }}
                </p>

                <hr />
              </div>
            </div>

            <div class="row">
              <div class="col col-sm-12 col-md-12">
                <p
                  class="text-h6 text-uppercase text-center mt-4 font-weight-medium"
                >
                  Programación anual y multianual del proyecto
                </p>
                <p
                  class="text-h7 text-left black--text mt-6"
                  style="font-weight: 600;"
                >
                  Fuente Cooperante
                </p>
                <template>
                  <v-simple-table class="table-bordered" dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Actividad/Subdivisión
                          </th>
                          <th class="text-center">
                            Monto
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in programacionesGenerales"
                          :key="item.descripcion"
                        >
                          <td>{{ item.descripcion }}</td>
                          <td class="text-right">
                            {{
                              parseFloat(
                                item.aporteFuenteCooperante
                              ).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>

                <p
                  class="text-h7 text-left black--text mt-8"
                  style="font-weight: 600;"
                >
                  Contrapartida
                </p>
                <template
                  v-if="programacionesGeneralesContraPartida.length > 0"
                >
                  <v-simple-table class="table table-bordered dense" dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Actividad/Subdivisión
                          </th>
                          <th class="text-center">
                            Monto
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in programacionesGeneralesContraPartida"
                          :key="item.name"
                        >
                          <td>{{ item.descripcion }}</td>
                          <td class="text-right">
                            {{
                              parseFloat(item.monto).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col col-sm-12 col-md-12">
                      <p
                        class="text-subtitle-1 text-left mt-4 font-weight-medium"
                      >
                        No existe información de contrapartida y otros aportes
                        registrada
                      </p>
                    </div>
                  </div>
                </template>

                <hr class="mt-6" />
                <p
                  class="text-h6 text-uppercase text-center mt-4 font-weight-medium"
                >
                  Proyección anual del proyecto por Cuatrimestre
                </p>
                <template>
                  <v-simple-table
                    class="table-bordered"
                    dense
                    style="border: 1px solid #ccc !important;"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Indicador
                          </th>
                          <th class="text-center">
                            Primer Cuatrimestre
                          </th>
                          <th class="text-center">
                            Segundo Cuatrimestre
                          </th>
                          <th class="text-center">
                            Tercer Cuatrimestre
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in programacionesCuatrimestre"
                          :key="item.name"
                        >
                          <td class="text-left">{{ item.variable }}</td>
                          <td class="text-right">
                            {{
                              parseFloat(
                                item.montoPrimerCuatrimestre
                              ).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                            }}
                          </td>
                          <td class="text-right">
                            {{
                              parseFloat(
                                item.montoSegundoCuatrimestre
                              ).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                            }}
                          </td>
                          <td class="text-right">
                            {{
                              parseFloat(
                                item.montoTercerCuatrimestre
                              ).toLocaleString("es-GT", {
                                style: "currency",
                                currency: "GTQ"
                              })
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </div>
            </div>
          </section>
        </vue-html2pdf>
      </div>
    </template> -->
    <!--fin:: impresion poa -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DownloadFile from "@/core/untils/downloadfile.js";
//import VueHtml2pdf from "vue-html2pdf";

import DialogLoader from "@/view/content/DialogLoader";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

import { OBTENER_DATOS_FINANCIEROS_CNS } from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";
import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";

import {
  OBTENER_PLANIFICACIONES_ANUALES_CNS,
  REGISTRAR_PLANIFICACION_ANUAL_CNS,
  OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CNS,
  REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CNS,
  OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CONTRAPARTIDA_CNS,
  REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CONTRAPARTIDA_CNS,
  OBTENER_PLANIFICACIONES_ANUALES_CUATRIMESTRE_CNS,
  REGISTRAR_PLANIFICACION_ANUAL_CUATRIMESTRE_CNS,
  REGISTRAR_ASIGNADO_ANUAL_POA_CNS,
  CONFIRMAR_PLANIFICACION_CNS,
  OBTENER_PLANIFICACION_ANUAL_CNS,
  ELIMINAR_SUBDIVISION_FC_PLANFICACION_ANUAL_CNS,
  ELIMINAR_SUBDIVISION_CP_PLANFICACION_ANUAL_CNS,
  ELIMINAR_PROYECCION_CUATRIMESTRE_PLANFICACION_ANUAL_CNS,
} from "@/core/services/store/proyectoscns/programacionesanuales/programacionanualcns.module";

import {
  OBTENER_PROYECCIONES_DESEMBOLSOS_CNS,
  OBTENER_EJECUCIONES_ACTIVIDAD_CNS, 
  OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS
} from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

import validations from "@/core/untils/validations.js";

export default {
  name: "AdminProgramacionAnualCns",
  data() {
    return {
      datosProyecto: {},
      validForm: false,
      validForm2: false,
      validForm3: false,

      validFormContrapartida: false,

      validFormMontoAsignado: false,
      datosFinancierosProyecto: [],
      fuenteCooperanteRegistrada: false,
      monedaFuenteCooperante: "",
      monedaContraPartida: "",
      monedaOtros: "",
      contraPartidaRegistrada: false,
      datoFinancieroFCRegistrado: 0,
      datoFinancieroCPRegistrado: 0,
      datoFinancieroOtrosRegistrado: 0,
      totalDatosFinancierosFC: 0.0,
      totalDatosFinancierosCP: 0.0,
      totalDatosFinancierosOtros: 0.0,
      totalContraPartidaOtros: 0.0,
      otrosRegistrado: false,
      btnRegistroMontoAsignadoLoading: false,
      idPeriodoSeleccionado: 0,
      periodoSeleccionado: 0,
      proyectoSeleccionado: "",
      dialogConfirmacion: false,

      programacionAnualMultiFuente: {},
      programacionAnualMultiContra: {},

      tablePlanificacionesLoading: false,
      ddRolesLoading: false,
      dialogRegistro: false,
      dialogRegistroInformacion: false,
      dialogLoaderVisible: false,
      dialogSuspension: false,
      dialogLoaderText: "",
      switchItemEstado: true,
      //proyectoSeleccionado: "",
      planificaciones: [],
      subDivisiones: [],
      subDivisionesCP: [],
      filtro: "",
      dialog: false,
      modalTitle: "Registrar nuevo proyecto",
      accion: 1,
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      btnEnvioLoading: false,
      btnRegistroProgramacionAnualLoading: false,
      btnRegistroProgramacionContraPartidaLoading: false,
      btnRegistroProgramacionCuatrimestralLoading: false,
      estadoProyecto: "",
      parameters: [],
      proyectoId: "",
      codigoProyecto: "",
      proyeccionesDesembolsos: [],
      totalDatosFinancieros: 0,
      datosItem: {
        id: 0,
        proyectosCNSId: this.proyectoId,
        periodosId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
        //montoAsignado: "",
      },
      datosProyeccionAnual: {
        ejecucionesPorActividadCnsId: 0,
        aporteFuenteCooperante: "",
        aporteContrapartida: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      datosProyeccionAnualContrapartida: {
        contraPartidasActividadesId: 0,
        programacionAnualCNSId: 0,
        monto: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      programacionesGenerales: [],
      tableProgramacionesGeneralesLoading: false,
      programacionesGeneralesContraPartida: [],
      tableProgramacionesGeneralesContraPartidaLoading: false,
      datosProyeccionCuatrimestre: {
        variablesCnsId: 0,
        montoPrimerCuatrimestre: "",
        montoSegundoCuatrimestre: "",
        montoTercerCuatrimestre: ""
      },

      datosMontoAsignado: {
        montoAsignado: 0,
        programacionAnualCNSId: 0
      },

      programacionesCuatrimestre: [],
      tableProgramacionesCuatrimestreLoading: false,
      periodoRegistrado: false,
      periodos: [],
      ddPeriodosLoading: false,
      estadosProyecto: [
        { text: "Todos", value: null },
        { value: 1, text: "En registro" },
        {
          value: 2,
          text: "Inactivo"
        },
        {
          value: 4,
          text: "Confirmado"
        }/*,
        { value: 7, text: "Suspendido" }*/
      ],
      totalSubDivisionContraPartida: 0.0,
      totalSubDivisionCooperante: 0,
      programacionSeleccionadaId: 0,
      //  periodoSeleccionado: "",
      progress: 0,
      datosProgramacion: [],
      dialogActualizarEstado: false,
      bimestreSeleccionado: "",
      anioSeleccionado: "",
      idEstadoSeleccionado: 0,
      seccionesBloqueadas: false,
      btnEliminarSubdivisionFCLoading: false,
      btnEliminarSubdivisionCPLoading: false,
      btnEliminarProyeccionCuatrimestreLoading: false,
      skeletonLoading: false,
      ...validations
    };
  },
  components: {
    DialogLoader,
    SnackAlert
    //VueHtml2pdf
  },
  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoProyecto.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoProyecto.text;
    },

    modalNuevo() {
      this.seccionesBloqueadas = false;
      this.accion = 1;
      this.modalTitle = "Registrar Índice";
      this.btnRegistroText = "Registrar Índice";
      this.switchItemEstado = true;
      this.dialogRegistro = true;
    },

    modalConfigurarProgramacion(idProgramacion, periodo, cargarModal) {
      this.datosMontoAsignado.montoAsignado = "";
      this.datosMontoAsignado.id = 0;
      this.programacionId = idProgramacion;
      this.datosProyeccionAnual.programacionAnualCNSId = idProgramacion;

      this.datosProyeccionAnualContrapartida.programacionAnualCNSId = idProgramacion;
      this.datosProyeccionCuatrimestre.programacionAnualCNSId = idProgramacion;
      this.obtenerDatosPoa(idProgramacion).then(() => {
        //  console.log(this.datosProgramacion)
        this.datosMontoAsignado.montoAsignado = this.datosProgramacion.montoAsignado.toString();
        this.datosMontoAsignado.id = idProgramacion;
      });
      this.obtenerProgramacionesAnuales(idProgramacion);
      this.obtenerProgramacionesAnualesContraPartida(idProgramacion);
      this.obtenerProgramacionesCuatrimestre(idProgramacion);
      this.obtenerEjecucionesPorActividad(this.proyectoId);
      this.obtenerEjecucionesPorActividadContrapartida(this.proyectoId);
      this.periodoSeleccionado = periodo;

      this.obtenerProyeccionesDesembolsos(this.proyectoId).then();
      if (cargarModal) {
        this.dialogRegistroInformacion = true;
      }
    },

    mostrarDialogConfirmacion(id, periodo) {
      this.idPeriodoSeleccionado = id;
      this.periodoSeleccionado = periodo;
      this.dialogConfirmacion = true;
    },

    async confirmarPlanificacion() {
      this.btnEnvioLoading = true;

      this.dialogLoaderText =
        "Actualizando información de la programación anual...";
      this.dialogLoaderVisible = true;
      // this.btnEnvioLoading=false;

      await this.$store
        .dispatch(CONFIRMAR_PLANIFICACION_CNS, {
          id: this.idPeriodoSeleccionado,
          estadoId: 4
        })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.datosProgramacion.estadosId = 4;
            this.datosProgramacion.estado = "Confirmado";
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogConfirmacion = false;
            this.descargarArchivo(res.data, "sicoopera");
            this.obtenerDatosPoa(this.idPeriodoSeleccionado);
            this.obtenerItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.dialogLoaderVisible = false;
          this.btnEnvioLoading = false;
        })
        .catch(error => {
          //console.logerror
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnEnvioLoading = false;
        });
    },

    mostrarModalActualizarEstado(id, periodo) {
      this.periodoSeleccionado = periodo;
      this.idPeriodoSeleccionado = id;
      this.idEstadoSeleccionado = 1;
      this.dialogActualizarEstado = true;
    },

    async actualizarEstadoPlanificacion() {
      this.btnEnvioLoading = true;
      await this.$store
        .dispatch(CONFIRMAR_PLANIFICACION_CNS, {
          id: this.idPeriodoSeleccionado,
          estadoId: this.idEstadoSeleccionado
        })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.datosProgramacion.estadosId = 4;
            this.idEstadoSeleccionado === 4
              ? (this.datosProgramacion.estado = "Confirmado")
              : (this.datosProgramacion.estado = "Activo");
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogConfirmacion = false;
            this.dialogActualizarEstado = false;
            this.obtenerItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.dialogLoaderVisible = false;
          this.btnEnvioLoading = false;
        })
        .catch(error => {
          //console.logerror
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnEnvioLoading = false;
        });
    },

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
    },

    //Obtener tipos de datos financieros
    async obtenerPeriodos() {
      this.periodos = [];

      this.ddPeriodosLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Periodos/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.periodos = res.data;
          }
          this.ddPeriodosLoading = false;
        })
        .catch(() => {
          this.periodos = [];
          this.ddPeriodosLoading = false;
        });
    },

    //Obtener datos de la planificación anual
    async obtenerDatosPoa(idProgramacion) {
      this.datosProgramacion = [];

      //   this.ddPeriodosLoading=true;
      this.seccionesBloqueadas = false;
      await this.$store
        .dispatch(OBTENER_PLANIFICACION_ANUAL_CNS, idProgramacion)
        .then(res => {
          if (res.status === 200) {
            this.datosProgramacion = res.data;
            if (this.datosProgramacion.estadosId === 4) {
              this.seccionesBloqueadas = true;
            }
          }
          // this.ddPeriodosLoading=false;
        })
        .catch(() => {
          this.datosProgramacion = [];
          //     this.ddPeriodosLoading=false;
        });
    },

    //Obtener los datos financieros
    async obtenerDatosFinancieros(proyectoId) {
      // this.tableDatosFinancierosLoading=true;
      this.datosFinancierosProyecto = [];
      this.contraPartidaRegistrada = false;
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.datosFinancierosProyecto = res.data;
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
              (a, b) => a + (b["montoTotalQuetzales"] || 0),
              0
            );

            //Validar si hay fuente cooperante registrada
            let searchObjectFC = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 1
            );
            if (searchObjectFC) {
              this.fuenteCooperanteRegistrada = true;
              this.monedaFuenteCooperante = searchObjectFC.codigoMoneda;
            }

            //console.log(searchObject);
            //this.datoFinancieroFCRegistrado = searchObjectFC.length;
            searchObjectFC
              ? (this.datoFinancieroFCRegistrado = searchObjectFC.length)
              : (this.datoFinancieroFCRegistrado = 0);
            //this.datoFinancieroFCRegistrado != 0 ? this.datoFinancieroFCRegistrado = true : this.datoFinancieroFCRegistrado = false;

            //Validar si hay contrapartida registrada
            let searchObjectCP = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 2
            );
            if (searchObjectCP) {
              this.monedaContraPartida = searchObjectCP.codigoMoneda;
              this.contraPartidaRegistrada = true;
            } else {
              this.contraPartidaRegistrada = false;
            }
            //console.log(searchObjectCP)

            searchObjectCP
              ? (this.datoFinancieroCPRegistrado = searchObjectCP.length)
              : (this.datoFinancieroCPRegistrado = 0);

            //Validar si hay otros registrado
            let searchObjectOtros = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 3
            );

            if (searchObjectOtros) {
              this.otrosRegistrado = true;
              this.monedaOtros = searchObjectOtros.codigoMoneda;
            } else {
              this.otrosRegistrado = false;
            }

            searchObjectOtros
              ? (this.datoFinancieroOtrosRegistrado = searchObjectOtros.length)
              : (this.datoFinancieroOtrosRegistrado = 0);

            //Sumar fuente cooperante
            this.totalDatosFinancierosFC = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 1
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            //console.log(this.totalDatosFinancierosFC)
            //Sumar total contrapartida
            /*this.totalDatosFinancierosCP = this.datosFinancierosProyecto.filter(({
                                                                                    tiposDatosFinancierosId
                                                                                }) => tiposDatosFinancierosId === 2).reduce((a,b) =>  a + (b['montoTotalQuetzales'] || 0), 0);
                    */

            this.totalDatosFinancierosCP = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) =>
                  tiposDatosFinancierosId === 2 || tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            this.totalDatosFinancierosOtros = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) =>
                  tiposDatosFinancierosId === 2 || tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            this.totalContraPartidaOtros =
              parseFloat(this.totalDatosFinancierosCP) +
              parseFloat(this.totalDatosFinancierosOtros);
          }
          //this.tableDatosFinancierosLoading=false;
        })
        .catch(() => {
          //console.logerror
          this.datosFinancierosProyecto = [];
          //this.tableDatosFinancierosLoading=false;
        });
    },

    //Obtener subdivisiones/actividades (Componente, objetivo, indicador, resultado, meta ) fuente cooperante
    async obtenerSubDivisiones() {
      this.subDivisiones = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `EjecucionesPorActividadCNS/all/1/${this.proyectoId}`
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisiones = res.data;
          }
        })
        .catch(() => {
          this.subDivisiones = [];
        });
    },

    //Obtener subdivisiones / actividades (Componente, objetivo, indicador, resultado, meta ) contrapartida
    async obtenerSubDivisionesContraPartida() {
      this.subDivisionesCP = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ContrapartidasActividades/all/1/${this.proyectoId}`
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisionesCP = res.data;
            this.totalSubDivisionContraPartida = this.subDivisionesCP.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subDivisionesCP = [];
        });
    },

    //Obtener varialbes
    async obtenerVariables() {
      this.variables = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `VariablesCNS/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.variables = res.data;
          }
        })
        .catch(() => {
          this.variables = [];
        });
    },

    //Obtener los registros de poa
    async obtenerItems() {
      this.planificaciones = [];
      this.tablePlanificacionesLoading = true;

      await this.$store
        .dispatch(OBTENER_PLANIFICACIONES_ANUALES_CNS, {
          proyectoId: this.proyectoId,
          estadoId: 0
        })
        .then(res => {
          if (res.status === 200) {
            this.planificaciones = res.data;
          }
          this.tablePlanificacionesLoading = false;
        })
        .catch(() => {
          this.planificaciones = [];
          this.tablePlanificacionesLoading = false;
        });
    },

    //Obteenr las programaciones anuales por actividad
    async obtenerProgramacionesAnuales(programacionId) {
      this.tableProgramacionesGeneralesLoading = true;
      this.programacionesGenerales = [];

      await this.$store
        .dispatch(OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CNS, {
          programacionAnualId: programacionId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.programacionesGenerales = res.data;
          }
          this.tableProgramacionesGeneralesLoading = false;
        })
        .catch(() => {
          this.programacionesGenerales = [];
          this.tableProgramacionesGeneralesLoading = false;
        });
    },

    //Obteenr las programaciones anuales por actividad de contrapartida
    async obtenerProgramacionesAnualesContraPartida(programacionId) {
      this.tableProgramacionesGeneralesContraPartidaLoading = true;
      this.programacionesGeneralesContraPartida = [];

      await this.$store
        .dispatch(
          OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CONTRAPARTIDA_CNS,
          { programacionAnualId: programacionId, estadoId: 1 }
        )
        .then(res => {
          if (res.status === 200) {
            this.programacionesGeneralesContraPartida = res.data;
          }
          this.tableProgramacionesGeneralesContraPartidaLoading = false;
        })
        .catch(() => {
          this.programacionesGeneralesContraPartida = [];
          this.tableProgramacionesGeneralesContraPartidaLoading = false;
        });
    },

    //Obtener las programaciones anuales por cuatrimestre
    async obtenerProgramacionesCuatrimestre(programacionId) {
      this.tableProgramacionesCuatrimestreLoading = true;
      this.programacionesCuatrimestre = [];

      await this.$store
        .dispatch(OBTENER_PLANIFICACIONES_ANUALES_CUATRIMESTRE_CNS, {
          proyectoId: programacionId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.programacionesCuatrimestre = res.data;
          }
          this.tableProgramacionesCuatrimestreLoading = false;
        })
        .catch(() => {
          this.programacionesCuatrimestre = [];
          this.tableProgramacionesCuatrimestreLoading = false;
        });
    },

    //Obtener el lsitado de proyeccion de desempbolosos registrados
    async obtenerProyeccionesDesembolsos(proyectoId) {
      this.proyeccionesDesembolsos = [];

      await this.$store
        .dispatch(OBTENER_PROYECCIONES_DESEMBOLSOS_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.proyeccionesDesembolsos = res.data;
            this.totalDatosFinancieros = this.proyeccionesDesembolsos.reduce(
              (a, b) => a + (b["montoOriginal"] * b["tipoCambio"] || 0),
              0
            );
          }
          this.tableLoadingProyecciones = false;
        })
        .catch(() => {
          this.proyeccionesDesembolsos = [];
        });
    },

    //Obtener las ejecuciones por actividdad de cns
    async obtenerEjecucionesPorActividad(proyectoId) {
      this.tableLoadingSubdivisionCooperante = true;
      this.proyeccionesSubDivision = [];
      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.proyeccionesSubDivision = res.data;
            this.totalSubDivisionCooperante = this.proyeccionesSubDivision.reduce(
              (a, b) => a + (b["aporteFuenteCooperante"] || 0),
              0
            );
          }
          this.tableLoadingSubdivisionCooperante = false;
        })
        .catch(() => {
          this.proyeccionesSubDivision = [];
          this.tableLoadingSubdivisionCooperante = false;
        });
    },

    async obtenerEjecucionesPorActividadContrapartida(proyectoId) {
      this.tableLoadingSubdivisionContrapartida=true;
      this.proyeccionesSubDivisionContrapartida = [];

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS, {
          id: proyectoId
        })
        .then(res => {
          if (res.status === 200) {
            this.proyeccionesSubDivisionContrapartida = res.data;
            this.totalSubDivisionContraPartida = this.proyeccionesSubDivisionContrapartida.reduce(( a,b) => a + (b['monto'] || 0), 0);

          }
          this.tableLoadingSubdivisionContrapartida = false;
        })
        .catch(() => {
          this.proyeccionesSubDivisionContrapartida = [];
          this.tableLoadingSubdivisionContrapartida = false;
        });
    },

    //Mostrar el dialogo para la suspension
    mostrarDialogSuspension(nombreProyecto) {
      this.proyectoSeleccionado = nombreProyecto;
      this.dialogSuspension = true;
    },

    resetForm() {
      this.totalDatosFinancierosFC = 0.0;
      this.totalDatosFinancierosCP = 0.0;
      this.totalDatosFinancierosOtros = 0.0;
      this.totalContraPartidaOtros = 0.0;
      this.datoFinancieroFCRegistrado = 0;
      this.datoFinancieroCPRegistrado = 0;
      this.datoFinancieroOtrosRegistrado = 0;
      this.fuenteCooperanteRegistrada = false;
      this.contraPartidaRegistrada = false;
      this.otrosRegistrado = false;
      (this.periodoRegistrado = false),
        (this.datosItem = {
          id: 0,
          proyectosCNSId: this.proyectoId,
          periodosId: 0,
          estadosId: 1,
          usuarioCreacion: "admin"
        });

      this.datosProyeccionAnual = {
        ejecucionesPorActividadCnsId: 0,
        aporteFuenteCooperante: "",
        aporteContrapartida: ""
      };

      this.datosProyeccionCuatrimestre = {
        id: 0,
        montoPrimerCuatrimestre: 0,
        montoSegundoCuatrimestre: 0,
        montoTercerCuatrimestre: 0,
        variablesCNSId: 0,
        programacionAnualCNSId: 10000,
        estadosId: 2,
        usuarioCreacion: "admin"
      };

      this.switchItemEstado = true;
      this.accion = 1;
    },

    resetItemsProgAnual() {
      this.datosProyeccionAnual.aporteFuenteCooperante = "";
      this.datosProyeccionAnual.aporteContrapartida = 0;
      this.datosProyeccionAnual.ejecucionesPorActividadCnsId = 0;

      this.datosProyeccionAnualContrapartida.monto = 0;
      this.datosProyeccionAnualContrapartida.ejecucionesPorActividadCnsId = 0;
    },

    resetItemsProgCuatrimestre() {
      this.datosProyeccionCuatrimestre.montoPrimerCuatrimestre = "";
      this.datosProyeccionCuatrimestre.montoSegundoCuatrimestre = "";
      this.datosProyeccionCuatrimestre.montoTercerCuatrimestre = "";
    },

    resetItemsMontoAnual() {
      this.datosMontoAsignado = {
        montoAsignado: 0,
        programacionAnualCNSId: this.programacionId
      };
    },

    ///Registro de usuario
    async registrarItem() {
      this.btnRegistroLoading = true;
      this.datosItem.proyectosCNSId = this.proyectoId;

      await this.$store
        .dispatch(REGISTRAR_PLANIFICACION_ANUAL_CNS, { datos: this.datosItem })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerItems();
            this.dialogRegistro = false;
            this.periodoRegistrado = true;
            this.btnRegistroLoading = false;
            //this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    async registrarProgramacionAnual() {
      if (
        parseFloat(this.datosProyeccionAnual.aporteFuenteCooperante) >
        parseFloat(this.programacionAnualMultiFuente.aporteFuenteCooperante)
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El monto que intenta registrar ${parseFloat(
            this.datosProyeccionAnual.aporteFuenteCooperante
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })} es maayor al monto proyectado en datos financieros para esta actividad 
                                                                 ${parseFloat(
                                                                   this
                                                                     .programacionAnualMultiFuente
                                                                     .aporteFuenteCooperante
                                                                 ).toLocaleString(
                                                                   "es-GT",
                                                                   {
                                                                     style:
                                                                       "currency",
                                                                     currency:
                                                                       "GTQ"
                                                                   }
                                                                 )}.`
        );
        return;
      }

      this.datosProyeccionAnual.ejecucionesPorActividadCNSId = this.programacionAnualMultiFuente.id;

      this.btnRegistroProgramacionAnualLoading = true;
      //this.datosProyeccionAnual.programacionAnualCNSId = this.proyectoId;
      this.datosProyeccionAnual.estadosId = 1;
      this.datosProyeccionAnual.usuarioCreacion = "admin";

      await this.$store
        .dispatch(REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CNS, {
          datos: this.datosProyeccionAnual
        })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProgramacionesAnuales(
              this.datosProyeccionAnual.programacionAnualCNSId
            );
            this.resetItemsProgAnual();

            this.btnRegistroProgramacionAnualLoading = false;
            //this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroProgramacionAnualLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });

      //this.programacionesGenerales.push({id: 1, subdivision: this.datosProyeccionAnual.ejecucionesPorActividadCnsId.nombreSubDivision, aporteFuenteCooperante: this.datosProyeccionAnual.aporteFuenteCooperante, aporteContrapartida: this.datosProyeccionAnual.aporteContrapartida});
    },

    //Registrar la programación anual para la contrapartida
    async registrarProgramacionAnualContrapartida() {
      if (
        parseFloat(this.datosProyeccionAnualContrapartida.monto) >
        parseFloat(this.programacionAnualMultiContra.monto)
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El monto que intenta registrar ${parseFloat(
            this.datosProyeccionAnualContrapartida.monto
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })} es maayor al monto proyectado en datos financieros para esta actividad 
                                                                 ${parseFloat(
                                                                   this
                                                                     .programacionAnualMultiContra
                                                                     .monto
                                                                 ).toLocaleString(
                                                                   "es-GT",
                                                                   {
                                                                     style:
                                                                       "currency",
                                                                     currency:
                                                                       "GTQ"
                                                                   }
                                                                 )}.`
        );
        return;
      }

      this.datosProyeccionAnualContrapartida.contraPartidasActividadesId = this.programacionAnualMultiContra.id;

      this.btnRegistroProgramacionContraPartidaLoading = true;
      //this.datosProyeccionAnual.programacionAnualCNSId = this.proyectoId;
      this.datosProyeccionAnual.estadosId = 1;
      this.datosProyeccionAnual.usuarioCreacion = "admin";

      await this.$store
        .dispatch(REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CONTRAPARTIDA_CNS, {
          datos: this.datosProyeccionAnualContrapartida
        })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProgramacionesAnualesContraPartida(
              this.datosProyeccionAnual.programacionAnualCNSId
            );
            this.resetItemsProgAnual();

            this.btnRegistroProgramacionContraPartidaLoading = false;
            //this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroProgramacionContraPartidaLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    async registrarProgramacionCuatrimestre() {
      //Validar que sea avance físico para validar que la suma de los 3 cuatrimestres no sea mayor a 100
      if (this.datosProyeccionCuatrimestre.variablesCnsId === 3) {
        if (
          parseFloat(this.datosProyeccionCuatrimestre.montoPrimerCuatrimestre) +
            parseFloat(
              this.datosProyeccionCuatrimestre.montoSegundoCuatrimestre
            ) +
            parseFloat(
              this.datosProyeccionCuatrimestre.montoTercerCuatrimestre
            ) >
          100
        ) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `La suma de los porcentajes de los cuatrimestres supera el 100%`
          );
          return;
        }
      }

      this.btnRegistroProgramacionCuatrimestralLoading = true;
      //this.datosProyeccionAnual.programacionAnualCNSId = this.proyectoId;
      this.datosProyeccionCuatrimestre.estadosId = 1;
      this.datosProyeccionCuatrimestre.usuarioCreacion = "admin";

      await this.$store
        .dispatch(REGISTRAR_PLANIFICACION_ANUAL_CUATRIMESTRE_CNS, {
          datos: this.datosProyeccionCuatrimestre
        })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProgramacionesCuatrimestre(
              this.datosProyeccionCuatrimestre.programacionAnualCNSId
            );
            this.resetItemsProgCuatrimestre();

            this.btnRegistroProgramacionCuatrimestralLoading = false;
            //this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroProgramacionCuatrimestralLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    async registrarMontoAsignado() {
      this.btnRegistroMontoAsignadoLoading = true;
      // this.$refs.snackalert.SnackbarShow("success", "Mensaje", `Se ha actualizado el monto asignado con éxito.`);

      let datosAsignacionMonto = {
        asignadoAnual: parseFloat(this.datosMontoAsignado.montoAsignado),
        programacionAnualCNSId: this.programacionId
      };

      await this.$store
        .dispatch(REGISTRAR_ASIGNADO_ANUAL_POA_CNS, datosAsignacionMonto)
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.btnRegistroMontoAsignadoLoading = false;

            //this.resetItemsMontoAnual();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroMontoAsignadoLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnRegistroMontoAsignadoLoading = false;
        });
    },

    imprimirPoa(id, periodo, nombreProyecto) {
      this.progress = 0;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";
      this.programacionSeleccionadaId = id;
      this.periodoSeleccionado = periodo;
      this.proyectoSeleccionado = nombreProyecto;

      this.obtenerProgramacionesAnuales(id).then(() => {
        this.progress = 25;
        this.dialogLoaderText = "Generando archivo...";
        this.obtenerProgramacionesAnualesContraPartida(id).then(() => {
          this.progress = 50;
          this.obtenerProgramacionesCuatrimestre(id).then(() => {
            this.progress = 75;
            this.dialogLoaderVisible = false;
            this.$emit("startPagination");
            this.paginationOfElements();
          });
        });
      });
    },

    paginationOfElements() {
      this.progress = 100;
      this.$refs.html2Pdf.generatePdf();
    },

    async obtenerDatosEspecificosProyecto(idProyecto) {
      this.skeletonLoading = true;
      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: idProyecto
        })
        .then(res => {
          if (res.status === 200) {
            this.datosProyecto = res.data;
            //console.log(this.datosProyecto);
          }
          this.skeletonLoading = false;
          //this.skeletonAvance[idProyecto] = false;
        })
        .catch(() => {
          //console.logerror
          //this.skeletonAvance[idProyecto] = false;
          this.dialogLoaderVisible = false;
          this.datosProyecto = [];
          this.skeletonLoading = false;
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    //Eliminar subdivisión Fuente Cooperante
    async eliminarSubdivisionFC(id, nombreActividad, descripcionActividad) {
      this.btnEliminarSubdivisionFCLoading = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText =
        "Eliminando registro subdivisión de la contrapartida " +
        nombreActividad +
        " " +
        descripcionActividad +
        "...";
      await this.$store
        .dispatch(ELIMINAR_SUBDIVISION_FC_PLANFICACION_ANUAL_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProgramacionesAnuales(this.programacionId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarSubdivisionFCLoading = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarSubdivisionFCLoading = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Eliminar subdivisión Contrapartida
    async eliminarSubdivisionCP(id, nombreActividad, descripcionActividad) {
      this.btnEliminarSubdivisionCPLoading = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText =
        "Eliminando registro subdivisión de la contrapartida " +
        nombreActividad +
        " " +
        descripcionActividad +
        "...";
      await this.$store
        .dispatch(ELIMINAR_SUBDIVISION_CP_PLANFICACION_ANUAL_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProgramacionesAnualesContraPartida(this.programacionId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarSubdivisionCPLoading = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarSubdivisionCPLoading = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Eliminar Proyección del cuatrimestre
    async eliminarProyeccionCuatrimestre(id, variable) {
      this.btnEliminarProyeccionCuatrimestreLoading = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText =
        "Eliminando variable de proyección por cuatrimestre " +
        variable +
        "...";
      await this.$store
        .dispatch(ELIMINAR_PROYECCION_CUATRIMESTRE_PLANFICACION_ANUAL_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProgramacionesCuatrimestre(this.programacionAnualId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarProyeccionCuatrimestreLoading = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarProyeccionCuatrimestreLoading = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(path, tipo) {
      if (tipo == "sigeaci") DownloadFile.downloadSigeaci(path);

      if (tipo == "sicoopera") DownloadFile.download(path);
    },

    //!Obtener datos de un item especifico
    obtenerDatosItem(Id) {
      return Id;
    }
  },
  created() {
    this.parameters = this.$route.params;
    this.proyectoId = this.$CryptoJS.AES.decrypt(
      this.parameters.proyectoId,
      "KEYADMINCNS2022"
    ).toString(this.CryptoJS.enc.Utf8);
    this.codigoProyecto = this.parameters.codigoProyecto;
    this.obtenerDatosEspecificosProyecto(this.proyectoId);
    this.obtenerDatosFinancieros(this.proyectoId);
    this.obtenerSubDivisiones();
    this.obtenerSubDivisionesContraPartida();
    this.obtenerPeriodos();
    this.obtenerItems();
    this.obtenerVariables();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Proyectos" },
      { title: "CNS", route: "proyectos-cns" },
      { title: "POA" }
    ]);

    // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
  },

  computed: {
    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },
    headers() {
      return [
        /*{
          text: "Id",
          align: "d-none",
          sortable: false,
          value: "anio"
        },*/
        {
          text: "Año",
          align: "start",
          sortable: true,
          value: "periodo"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    headersProgramacion() {
      return [
        /*{
          text: "Id",
          align: "start",
          sortable: false,
          value: "anio"
        },*/
        {
          text: "Actividad/Subdivisión",
          align: "start",
          sortable: true,
          value: "variable"
        },
        {
          text: "Monto",
          align: "start",
          sortable: true,
          value: "aporteFuenteCooperante"
        },
        /*{
          text: "Aporte contrapartida",
          align: "start",
          sortable: true,
          value: "aporteContrapartida"
        },*/
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    headersCuatrimestre() {
      return [
        /*{
          text: "Id",
          align: "start",
          sortable: false,
          value: "anio"
        },*/
        {
          text: "Variable",
          align: "start",
          sortable: true,
          value: "variable"
        },
        {
          text: "Monto primer cuatrimestre",
          align: "start",
          sortable: true,
          value: "montoPrimerCuatrimestre"
        },
        {
          text: "Monto segundo cuatrimestre",
          align: "start",
          sortable: true,
          value: "montoSegundoCuatrimestre"
        },
        {
          text: "Monto tercer cuatrimestre",
          align: "start",
          sortable: true,
          value: "montoTercerCuatrimestre"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  },

  watch: {
    progress(val) {
      this.$emit("progress", val);
    }
  }
};
</script>
